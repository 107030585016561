<template>
    <div style="width: 100%;height: 100%; background-color: #ffc99c;">
        <!--        <iframe ref="iframe" src="http://service.guocode.com/jr" width="100%" height="100%"></iframe>-->

        <div id="fullscreen" @click="handleFullScreen" v-if="fullscreenState === true">
            <svg-icon icon-class="fullscreen-icon" class="fullscreen-icon-class"></svg-icon>
        </div>
        <iframe ref="iframe" id="frameJr" :src="url" width="100%" height="100%"
            :style="{ visibility: showState ? 'visible' : 'hidden' }" allowfullscreen></iframe>

        <div class="iframe-load-z-index" v-if="state">
            <img class="load-gif" src="https://storage.shubenji.cn/GuoBao/game/lottie.gif" alt="">
        </div>

        <!--        </div>-->
    </div>
</template>

<script>
import { full } from "@/views/ipad/common";
import TeacherUser from "@/models/Home/TeacherUser";
import axios from "axios";

export default {
    name: "ScratchJrIframe",
    data() {
        return {
            state: true,
            showState: false,
            fullscreenState: false,
            // url: 'http://192.168.50.193:8080/pro/'
            url: '',
        }
    },
    created() {
        // if (this.$route.query.url !== '') {
        //     this.url = this.$route.query.url;
        // }
        if (localStorage.getItem('info') !== null) {
            // 服务端拿的 token 可以从 vuex、localStorage 等地方取
            axios.defaults.headers['token'] = JSON.parse(localStorage.getItem('info')).token;
        } else {
            axios.defaults.headers['token'] = ``;
        }
        window.addEventListener("message", this.handleMessage)
    },
    mounted() {
        this.homeBasisInfo();
        this.$refs.iframe.addEventListener("load", () => this.state = false)

        const url = this.$route.query.url;
        this.showState = true;

        if (this.$route.query.stuLessonId !== undefined) {
            this.url = url + "?stuLessonId=" + this.$route.query.stuLessonId + '&aa=1';
        } else if (this.$route.query.workId !== undefined) {
            this.url = url + "?workId=" + this.$route.query.workId + '&aa=1';
        } else {
            this.url = url
        }
    },

    methods: {
        //首页基本信息
        homeBasisInfo() {
            TeacherUser.getBasisInfo({}, ({ type, list }) => {
                if (type === 200) {
                    // this.homeBasisData = list;
                    // this.nickname = list.userinfo.nickname;
                    // this.avatarImg = list.userinfo.user_avatar;
                    localStorage.setItem("goodsCategory", list.bureau.goods_category);
                }
            })
        },
        handleMessage(msg) {
            let res = msg.data;
            if (res.name === 'returnNext') {
                this.$router.back()
            }
        },
        handleFullScreen() {
            full.Fullscreen("#app");
            const mapFrame = this.$refs.iframe;
            const iframeWin = mapFrame.contentWindow;
            iframeWin.postMessage(
                {
                    value: 'saveStage',
                    id: 'vue',
                    success: true
                },
                '*'
            );
        },
        sendMesFroIframe() {
            // 向iframe传值
            setTimeout(() => {
                const mapFrame = this.$refs.iframe;
                const iframeWin = mapFrame.contentWindow;
                iframeWin.postMessage(
                    {
                        value: 'backSuccess',
                        id: 'vue',
                        success: true
                    },
                    '*'
                );
            }, 0)

        },
    },
    destroyed() {
        window.removeEventListener("message", this.handleMessage)
    },
}
</script>

<style lang="less" scoped>
iframe {
    border-width: 0px !important;
}

.iframe-load-z-index {
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 0;
    z-index: 100000;
    display: flex;
    align-items: center;
    justify-content: center;
}

.load-gif {
    width: 10rem;
    height: 10rem;
}

#fullscreen {
    position: fixed;
    width: 4rem;
    height: 4rem;
    right: 20rem;
    top: 1rem;

    .fullscreen-icon-class {
        width: 3rem;
        height: 3rem;
        color: #333333;
    }
}
</style>
